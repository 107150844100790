import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import SEO from '../../components/seo'

class ProductsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItemIndex: 0
    }
  }

  toggleMenu = (index) => {
    this.setState({ activeItemIndex: index })
  }

  
  render() {
    return (
      <StaticQuery 
        query={graphql`
          query ProductsBenefits {
            benefit: markdownRemark(fields: { parent: { relativeDirectory: { eq: "products/who-benefits"}, name: {eq: "index"}}}) {
              html
            }
            benefitItems: allMarkdownRemark(
              sort: {fields: fields___parent___name},
              filter: { fields: { parent: { relativeDirectory: { eq: "products/who-benefits"}, name: {ne: "index"}}}}
            ) {
              nodes {
                html
                frontmatter {
                  title
                  image {
                    childImageSharp {
                      fixed(width: 82) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const { benefit, benefitItems } = data
          
          return (
            <>
              <SEO title="Products" />
      
              <section className="products-content">
                <div
                  dangerouslySetInnerHTML={{ __html: benefit.html }}
                />
              </section>
      
              <section className="full-width products-benefits">
                <div className="products-benefits__left">
                  {
                    benefitItems.nodes.filter((item,index) => index % 2 === 0).map((item, index) => {
                      return (
                        <div
                          role="button"
                          tabIndex={index*2+1}
                          key={index}
                          className={ this.state.activeItemIndex === index*2? 'products-benefits__menuItem-left active' : 'products-benefits__menuItem-left' }
                          onClick={() => { this.toggleMenu(index*2) }}
                          onKeyUp={() => { this.toggleMenu(index*2) }}
                        >
                          <h4>{item.frontmatter.title}</h4>
                          <Img className="products-benefits__image" fixed={item.frontmatter.image.childImageSharp.fixed} />
                        </div>
                      )
                    })
                  }
                </div>
      
                <div className="products-benefits__main">
                  {
                    benefitItems.nodes.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={index === this.state.activeItemIndex? 'products-benefits__item selected' : 'products-benefits__item'}
                          dangerouslySetInnerHTML={{ __html: item.html }}
                        />
      
                      )
                    })
                  }
                </div>
      
                <div className="products-benefits__right">
                  {
                    benefitItems.nodes.filter((item,index) => index % 2 !== 0).map((item, index) => {
                      return (
                        <div
                          key={index}
                          role="button"
                          tabIndex={index*2+2}
                          className={ this.state.activeItemIndex === index*2+1? 'products-benefits__menuItem-right active' : 'products-benefits__menuItem-right' }
                          onClick={() => { this.toggleMenu(index*2+1) }}
                          onKeyUp={() => { this.toggleMenu(index*2+1) }}
                        >
                          <Img className="products-benefits__image" fixed={item.frontmatter.image.childImageSharp.fixed} />
                          <h4>{item.frontmatter.title}</h4>
                        </div>
                      )
                    })
                  }
                </div>
              </section>
            </>
          )
        }}
      />
    )

  }
}

export default ProductsPage